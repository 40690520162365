import React from "react";
import styles from "../SideForm.module.css";

export default function ModelDropDown({
  prefix = "",
  setSelectedModel,
  selectedModel,
  register,
  activeTabKey
}) {
  return (
    <select
      {...register(prefix + "model")}
      className={styles.dropdown_model}
      onChange={(e) => setSelectedModel(e.target.value)}
      value={selectedModel}
    >
      {/* Conditionally render options based on activeTabKey */}
      {activeTabKey === "correlation" ? (
        // Only display ERA5 Monthly for the "correlation" tab
        <optgroup label="Allowed Model">
          <option value="era5_monthly">ERA5 Monthly</option>
        </optgroup>
      ) : (
        <>
          {/* Render Real-time Models if activeTabKey is not "correlation" */}
          <optgroup label="Real-time Models">
            <option value="gfs">GFS (hourly)</option>
            <option value="gfs_daily">GFS (daily)</option>
            <option value="hrrr">HRRR Hi-Res (hourly)</option>
            <option value="nam">NAM Hi-Res (hourly)</option>
            <option value="graphcastgfs">Graphcast (6 hourly)</option>
          </optgroup>
          {/* Render all Historical Models */}
          <optgroup label="Historical">
            <option value="era5_daily">ERA5 Daily</option>
            <option value="era5_monthly">ERA5 Monthly</option>
            <option value="reanalysis_24">Reanalysis 24h</option>
            <option value="reanalysis_season">Reanalysis Season</option>
          </optgroup>
        </>
      )}
    </select>
  );
}
