import axios from "axios";
import { Tab, Nav, Dropdown } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import { formatInTimeZone } from 'date-fns-tz';
import React, { createContext, useState, useEffect } from "react";
import { useCoordinates } from "../CoordinatesContext";
import InstantTab from "./SideMenuTabs/InstantTab";
import RangeTab from "./SideMenuTabs/RangeTab";
import GraphTab from "./SideMenuTabs/GraphTab";
import CorrelationTab from "./SideMenuTabs/CorrelationTab";
import styles from "./SideForm.module.css";

function calculateBoxArea(coords) {
  let area = 0;

  if (coords.length > 2) {
    for (let i = 0; i < coords.length - 1; i++) {
      area += coords[i][0] * coords[i + 1][1] - coords[i + 1][0] * coords[i][1];
    }
    area = Math.abs(area / 2);
  }

  return area;
}

const MyTiff = createContext(null);

export default function SideMenu({
  setWeatherFile,
  setRampType,
  setRampScale,
  rampType,
  rampScale,
  setColorBlind,
  parameter,
  parameter2,
  setParameter,
  setParameter2,
  opacity,
  setOpacity,
  selectedModel,
  setSelectedModel,
  setSubmitted,
  activeTabKey,
  setActiveTabKey,
  displayPopupImage,
  setCorrelate,
  firstPolygonCoords,
  secondPolygonCoords,
}) {
  const now = new Date();
  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);

  const [coordinates, setCoordinates] = useCoordinates();
  const [raster, setRaster] = useState([]);
  const [forecastHour, setForecastHour] = useState(0); // forecast hour
  const [modelRun, setModelRun] = useState("NA"); // model run date
  const [tiffStats, setTiffStats] = useState([0, 0, 0]); // min max mean
  const [loading, setLoading] = useState(false);
  const [allModels, setAllModels] = useState({});
  const [hasShownAlert, setHasShownAlert] = useState(false);
  const [hasMadeDefaultRequest, setHasMadeDefaultRequest] = useState(false);
  const [selectedDate, setSelectedDate] = useState(now);
  const [increment, setIncrement] = useState(1);
  const [incrementUnit, setIncrementUnit] = useState("hours");

  // For graphs
  const MAX_AGGS_BOX_AREA_QUART_DEG = 8000;
  const MAX_AGGS_BOX_AREA_3KM = 300;

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  // All possible tabs
  const allTabs = ["instant", "range", "graph", "correlation"];

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      // model: "gfs",
      start_date: now,
    },
  });

  useEffect(() => {
    if (modelRun !== "NA" && selectedDate) {
      calculateForecastHour(modelRun, selectedDate); // Recalculate forecast hour when modelRun changes
    }
  }, [modelRun, selectedDate]); // Only trigger this effect when modelRun or selectedDate changes

  const handleSelect = (key) => {
    // Update the active tab
    setActiveTabKey(key);
    setValue("tab", key);
  
    // Set `setCorrelate` based on the selected tab
    if (key === "correlation") {
      setCorrelate(true);
    } else {
      setCorrelate(false);
  
      // Clear the weather file when leaving the "correlation" tab
      if (activeTabKey === "correlation") {
        setWeatherFile(null);
      }
    }
  
    // Adjust the date based on the active tab
    let startTime;
    if (key === "instant") {
      startTime = watch("instant_start_date") || now;
    } else if (key === "range") {
      startTime = watch("range_start_date") || now;
    } else if (key === "graph") {
      startTime = watch("graph_start_date") || now;
    } else if (key === "correlation") {
      startTime = watch("correlation_start_date") || now;
    }
  
    setSelectedDate(startTime);
  };
  
  // Watch start_date in all tabs
  const instantStartDate = watch("instant_start_date");
  const rangeStartDate = watch("range_start_date");
  const graphStartDate = watch("graph_start_date");
  const correlationStartDate = watch("correlation_start_date");

  useEffect(() => {
    let activeStartDate = selectedDate; // Default to selectedDate

    if (activeTabKey === "range") {
      activeStartDate = rangeStartDate || selectedDate;
    } else if (activeTabKey === "graph") {
      activeStartDate = graphStartDate || selectedDate;
    } else if (activeTabKey === "correlation") {
      activeStartDate = correlationStartDate || selectedDate;
    } else {
      activeStartDate = instantStartDate || selectedDate;
    }

    if (modelRun && activeStartDate) {
      calculateForecastHour(modelRun, activeStartDate); // Recalculate forecastHour whenever the start_date changes
    }
  }, [
    rangeStartDate,
    graphStartDate,
    instantStartDate,
    correlationStartDate,
    modelRun,
    activeTabKey,
  ]);

  const calculateForecastHour = (modelRun, selectedDate) => {
    if (!modelRun || !selectedDate) return;

    // Extract the date and hour from the modelRun string (e.g., "2024-10-12 (13Z)")
    const runDate = modelRun.slice(0, 10); // Extract the date part (first 10 characters)
    const runHourPart = modelRun.match(/\((\d{2})Z\)/); // Extract the hour part from "(13Z)"
    const runHour = runHourPart ? parseInt(runHourPart[1], 10) : 0; // Get the hour, default to 0 if not found

    // Use the Date constructor with individual year, month, and day values for reliability
    const runDateParts = runDate.split("-"); // e.g., ["2024", "10", "12"]
    const modelRunDate = new Date(
      Date.UTC(
        parseInt(runDateParts[0]), // year
        parseInt(runDateParts[1]) - 1, // month (0-based index in JS)
        parseInt(runDateParts[2]), // day
        runHour, // hour (from "13Z")
        0, // minutes
        0 // seconds
      )
    );

    // Convert selectedDate to UTC for comparison
    const selectedDateUTC = new Date(selectedDate.toUTCString());

    // Calculate the difference in milliseconds and then convert to hours
    const millisecondsDifference = selectedDateUTC - modelRunDate;
    const hoursDifference = Math.floor(
      millisecondsDifference / (1000 * 60 * 60)
    ); // Convert to hours

    // Update the forecastHour in the state, ensuring it is not NaN
    setForecastHour(isNaN(hoursDifference) ? 0 : hoursDifference);
  };

  // Fetch the latest model run stats
  const fetchModelRunStats = () => {
    if (selectedModel) {
      axios
        .get(`${backendUrl}/api/model-run?model=${selectedModel}`)
        .then((response) => {
          const latestModelRun = response.data.latestModelRun; // Example: "2024-10-12 (06Z)"
          setModelRun(latestModelRun);
          setAllModels(response.data.modelRuns);
        })
        .catch((error) => {
          console.error("Failed to fetch latest model run:", error);
        });
    }
  };

  useEffect(() => {
    if (selectedModel) {
      fetchModelRunStats(); // Fetch model run stats only when the model changes
    }
  }, [selectedModel]);

  // Set up an interval to fetch model run stats every minute
  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchModelRunStats();
    }, 45000); // fetch every 45 seconds

    // Cleanup the interval when component unmounts
    return () => clearInterval(intervalId);
  }, [modelRun, selectedDate]);

  // This happens only once (default map) when the user first comes to the page, once modelRun updates
  useEffect(() => {
    if (!hasMadeDefaultRequest && modelRun !== "NA") {
      // Check if modelRun is updated and not "NA"
      if (modelRun !== "NA") {
        // Create a new date object that adjusts for the timezone offset
        const adjustedDate = formatInTimeZone(
          selectedDate,
          "UTC",
          "yyyy-MM-dd HH:00:00"
        );

        // Define the default weather request
        const defaultWeatherRequest = {
          location: [
            [
              [-151.56, 9.86],
              [-151.56, 58.69],
              [-44.35, 58.69],
              [-44.35, 9.86],
              [-151.56, 9.86],
            ],
          ],
          parameter: "combined",
          datetime: format(adjustedDate, "yyyy-MM-dd HH:00:00"),
          model: selectedModel,
          model_res: process.env.REACT_APP_API_KEY,
          forecast_hour: 0,
          modelRun: modelRun,
        };
        setParameter("combined");
        // Prepare the request options
        const options_download = {
          url: `${backendUrl}/api/instant`, // URL to your /api/basic endpoint
          method: "POST",
          responseType: "blob", // As per your requirement
          data: defaultWeatherRequest,
        };

        // Make the API request
        axios(options_download)
          .then(function (response) {
            setHasMadeDefaultRequest(true);
            // Handle the response, e.g., update the map with the received data
            const href = URL.createObjectURL(response.data);
            setWeatherFile(href); // Assuming setWeatherFile updates the map
            const latestModelRun = response.data.latestModelRun;
            calculateForecastHour(latestModelRun, selectedDate);

            let min = response.headers["x-stats-min"];
            let max = response.headers["x-stats-max"];
            let mean = response.headers["x-stats-mean"];

            setTiffStats([min, max, mean]);

            let warning = response.headers["x-warning"];
            if (warning && warning !== "False") {
              alert(`Warning: ${warning}`); // Display the warning in an alert
            }
          })
          .catch(function (error) {
            console.error("Error while fetching default weather data:", error);
          });
      }
    }
  }, [modelRun]);

  useEffect(() => {
    setValue("tab", activeTabKey);
    if (activeTabKey === "graph") {
      setIncrement(1);
    }
  }, [activeTabKey, setValue]);

  function formatCoords(coords) {
    const formatted_coords = [];
    if (Array.isArray(coords)) {
      coords.forEach((coord) => {
        const formatted = [
          Number(parseFloat(coord.lng).toFixed(2)),
          Number(parseFloat(coord.lat).toFixed(2)),
        ];
        formatted_coords.push(formatted);
      });
      formatted_coords.push(formatted_coords[0]); // Close the loop
    } else {
      const formatted = [
        Number(parseFloat(coords.lng).toFixed(2)),
        Number(parseFloat(coords.lat).toFixed(2)),
      ];
      formatted_coords.push(formatted);
    }
    return formatted_coords;
  }

  const onSubmit = (data) => {
    let coords;
    let formatted_coords, formatted_coords_first, formatted_coords_second;

    if (activeTabKey === "correlation") {
      // console.log(data)
      formatted_coords_first = formatCoords(JSON.parse(firstPolygonCoords));
      formatted_coords_second = formatCoords(JSON.parse(secondPolygonCoords));
    } else {
      coords = JSON.parse(coordinates);
      formatted_coords = formatCoords(coords);
    }

    let model,
      param,
      weather_request,
      api_url,
      utc_date,
      utc_start_date,
      utc_end_date,
      start_time,
      end_time;

    let API_KEY = process.env.REACT_APP_API_KEY;

    switch (data.tab) {
      // Handle each tab differently
      case "range":
      case "graph":
      case "correlation":
        // Prepare an array to hold the areas to validate
        let areasToValidate = [];

        if (data.tab === "correlation") {
          // We have two boxes to validate in the "correlation" tab
          const area_first = calculateBoxArea(formatted_coords_first);
          const area_second = calculateBoxArea(formatted_coords_second);

          areasToValidate.push({ area: area_first, boxNumber: 1 });
          areasToValidate.push({ area: area_second, boxNumber: 2 });
        } else {
          // Single box to validate in other tabs
          const area = calculateBoxArea(formatted_coords);

          areasToValidate.push({ area: area, boxNumber: "" });
        }

        let isValid = true;

        for (let { area, boxNumber } of areasToValidate) {
          if (
            area > MAX_AGGS_BOX_AREA_3KM &&
            !hasShownAlert &&
            (data.range_model === "nam" || data.range_model === "hrrr")
          ) {
            setHasShownAlert(true);
            alert(
              `Drawn Weather Window${
                boxNumber ? " " + boxNumber : ""
              } is too large for 3 KM resolution graphing or aggregations. Please draw a smaller one.`
            );
            isValid = false;
            break;
          } else if (area > MAX_AGGS_BOX_AREA_QUART_DEG && !hasShownAlert) {
            setHasShownAlert(true);
            alert(
              `Drawn Weather Window${
                boxNumber ? " " + boxNumber : ""
              } is too large for quarter degree resolution graphing or aggregations. Please draw a smaller one.`
            );
            isValid = false;
            break;
          }
        }

        if (!isValid) {
          return; // Prevent the form from submitting and the API call from being made
        }

        setHasShownAlert(false);

        if (data.tab === "range") {
          model = data.range_model;
          param = data.range_parameter;
          start_time = data.range_start_date;
          end_time = data.range_end_date;
        } else if (data.tab === "graph") {
          model = data.graph_model;
          param = data.graph_parameter;
          start_time = data.graph_start_date;
          end_time = data.graph_end_date;
        } else if (data.tab === "correlation") {
          model = data.correlation_model;
          param = data.correlation_parameter;
          start_time = data.correlation_start_date;
          end_time = data.correlation_end_date;
        } else {
          // Fallback to instant model
          model = data.instant_model;
          param = data.instant_parameter;
          start_time = data.instant_start_date;
        }

        if (model === "era5_monthly") {
          utc_start_date = formatInTimeZone(
            start_time,
            "UTC",
            "yyyy-MM-01 00:00:00"
          );
          utc_end_date = formatInTimeZone(
            end_time,
            "UTC",
            "yyyy-MM-01 00:00:00"
          );
        } else if (model === "era5_daily" || model === "gfs_daily") {
          utc_start_date = formatInTimeZone(
            start_time,
            "UTC",
            "yyyy-MM-dd 00:00:00"
          );
          utc_end_date = formatInTimeZone(
            end_time,
            "UTC",
            "yyyy-MM-dd 00:00:00"
          );
        } else {
          utc_start_date = formatInTimeZone(
            start_time,
            "UTC",
            "yyyy-MM-dd HH:00:00"
          );
          utc_end_date = formatInTimeZone(
            end_time,
            "UTC",
            "yyyy-MM-dd HH:00:00"
          );
        }

        if (activeTabKey !== "correlation") {
          weather_request = {
            location: [formatted_coords],
            parameter: param,
            start_time: utc_start_date,
            end_time: utc_end_date,
            model_res: API_KEY,
            modelRun: modelRun,
          };
        } else {
          // Set weather_request for correlation tab
          weather_request = {
            location1: [formatted_coords_first],
            location2: [formatted_coords_second],
            correlation_type: data.correlation_type,
            parameter1: param,
            parameter2: parameter2,
            start_time: utc_start_date,
            end_time: utc_end_date,
            model_res: API_KEY,
            modelRun: modelRun,
          };
        }

        weather_request.step = increment;
        weather_request.stepUnit = incrementUnit;

        if (data.tab === "graph") {
          weather_request.model = data.graph_model;
        } else if (data.tab === "correlation") {
          weather_request.model = selectedModel;
        } else {
          weather_request.model = data.range_model;
        }

        api_url = `${backendUrl}/api/${data.tab}`;
        break;

      case "instant":
        // For era5 data we are only looking at monthly averages or totals so we don't need to worry about timezones
        if (data.instant_model === "era5_monthly") {
          utc_date = format(data.instant_start_date, "yyyy-MM-01 00:00:00");
        } else if (data.instant_model === "reanalysis_season") {
          if (parameter === "snow") {
            utc_date = format(
              data.instant_start_date,
              "yyyy-09-30 12:00:00"
            );
          } else {
            utc_date = format(
              data.instant_start_date,
              "yyyy-01-01 00:00:00"
            );
          }
        } else if (
          data.instant_model === "era5_daily" ||
          data.instant_model === "reanalysis_24"
        ) {
          utc_date = format(
            data.instant_start_date,
            "yyyy-MM-dd 00:00:00"
          );
        } else {
          // Format the local_date to a string in the format you desire
          utc_date = formatInTimeZone(
            data.instant_start_date, // The date you want to convert
            "UTC", // Target timezone
            "yyyy-MM-dd HH:00:00" // Desired format
          );
        }
        param = data.instant_parameter;
        model = data.instant_model;

        weather_request = {
          location: [formatted_coords],
          parameter: param,
          datetime: utc_date,
          model: model,
          model_res: API_KEY,
          modelRun: modelRun,
        };

        api_url = `${backendUrl}/api/instant`;

        break;

      default:
        console.error(`Unexpected data.tab value: ${data.tab}`);
        return;
    }

    setSelectedDate(start_time || data.instant_start_date);
    calculateForecastHour(modelRun, start_time || data.instant_start_date);

    const options_download = {
      url: api_url, // your url
      method: "POST",
      responseType: "blob", // important
      data: weather_request,
    };

    setLoading(true);
    axios(options_download)
      .then(function (response) {
        setLoading(false);
        const contentType = response.headers["content-type"];

        setModelRun(response.headers["x-model-run"]);
        const latestModelRun = response.data.latestModelRun;
        calculateForecastHour(latestModelRun, selectedDate);

        if (contentType === "image/png") {
          const imageHref = URL.createObjectURL(response.data);
          let min = response.headers["x-stats-min"];
          let max = response.headers["x-stats-max"];
          let mean = response.headers["x-stats-mean"];
          setTiffStats([min, max, mean]);

          let warning = response.headers["x-warning"];
          if (warning && warning !== "False") {
            alert(`Warning: ${warning}`); // Display the warning in an alert
          }
          // Trigger the function to display the image in a popup
          displayPopupImage(imageHref);
        } else if (contentType === "image/tiff") {
          const href = URL.createObjectURL(response.data);

          let min = response.headers["x-stats-min"];
          let max = response.headers["x-stats-max"];
          let mean = response.headers["x-stats-mean"];
          setTiffStats([min, max, mean]);

          let warning = response.headers["x-warning"];
          if (warning && warning !== "False") {
            alert(`Warning: ${warning}`); // Display the warning in an alert
          }

          MyTiff.Provider = href;
          setWeatherFile(href);
          setRaster(response.data);
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.error(error);
      });
  };

  // Function to capitalize the first letter of the tab names
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className={styles.sideform_container}>
      <div
        className={`${
          activeTabKey === "instant"
            ? styles.sideform_instant
            : activeTabKey === "range"
            ? styles.sideform_range
            : activeTabKey === "graph"
            ? styles.sideform_graph
            : styles.sideform_correlation
        }`}
      >
        <Tab.Container activeKey={activeTabKey} onSelect={handleSelect}>
          {/* Adjusted Nav component */}
          <Nav className={`${styles.tabsContainer} custom-tabs`}>
            {/* Tab Name */}
            <Nav.Item className={styles.tabNameContainer}>
              <span className={styles.tabName}>
                {capitalizeFirstLetter(activeTabKey)}
              </span>
            </Nav.Item>

            {/* "More" Dropdown */}
            <Nav.Item className={styles.moreDropdownContainer}>
              <Dropdown>
                <Dropdown.Toggle
                  as={Nav.Link}
                  className={`${styles.moreDropdown} ${styles.compressedDropdown}`}
                >
                  More
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {allTabs
                    .filter((tab) => tab !== activeTabKey)
                    .map((tab) => (
                      <Dropdown.Item
                        key={tab}
                        eventKey={tab}
                        active={activeTabKey === tab}
                        onClick={() => handleSelect(tab)}
                      >
                        {capitalizeFirstLetter(tab)}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="instant">
              <InstantTab
                register={register}
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors}
                setValue={setValue}
                selectedModel={selectedModel}
                setSelectedModel={setSelectedModel}
                modelRun={modelRun}
                setModelRun={setModelRun}
                allModels={allModels}
                forecastHour={forecastHour}
                tiffStats={tiffStats}
                now={now}
                activeTabKey={activeTabKey}
                loading={loading}
                setParameter={setParameter}
                setSubmitted={setSubmitted}
                parameter={parameter}
                setRampType={setRampType}
                setRampScale={setRampScale}
                rampType={rampType}
                rampScale={rampScale}
                setColorBlind={setColorBlind}
                opacity={opacity}
                setOpacity={setOpacity}
                increment={increment}
                setIncrement={setIncrement}
                incrementUnit={incrementUnit}
                setIncrementUnit={setIncrementUnit}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="range">
              <RangeTab
                register={register}
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors}
                setValue={setValue}
                selectedModel={selectedModel}
                setSelectedModel={setSelectedModel}
                modelRun={modelRun}
                setModelRun={setModelRun}
                allModels={allModels}
                forecastHour={forecastHour}
                tiffStats={tiffStats}
                now={now}
                activeTabKey={activeTabKey}
                loading={loading}
                setParameter={setParameter}
                setSubmitted={setSubmitted}
                parameter={parameter}
                setRampType={setRampType}
                setRampScale={setRampScale}
                rampType={rampType}
                rampScale={rampScale}
                setColorBlind={setColorBlind}
                opacity={opacity}
                setOpacity={setOpacity}
                increment={increment}
                setIncrement={setIncrement}
                incrementUnit={incrementUnit}
                setIncrementUnit={setIncrementUnit}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="graph">
              <GraphTab
                register={register}
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors}
                setValue={setValue}
                selectedModel={selectedModel}
                setSelectedModel={setSelectedModel}
                modelRun={modelRun}
                setModelRun={setModelRun}
                allModels={allModels}
                forecastHour={forecastHour}
                tiffStats={tiffStats}
                now={now}
                activeTabKey={activeTabKey}
                loading={loading}
                setParameter={setParameter}
                setSubmitted={setSubmitted}
                parameter={parameter}
                setRampType={setRampType}
                setRampScale={setRampScale}
                rampType={rampType}
                rampScale={rampScale}
                setColorBlind={setColorBlind}
                opacity={opacity}
                setOpacity={setOpacity}
                increment={increment}
                setIncrement={setIncrement}
                incrementUnit={incrementUnit}
                setIncrementUnit={setIncrementUnit}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="correlation">
              <CorrelationTab
                register={register}
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors}
                setValue={setValue}
                selectedModel={selectedModel}
                setSelectedModel={setSelectedModel}
                modelRun={modelRun}
                setModelRun={setModelRun}
                allModels={allModels}
                forecastHour={forecastHour}
                tiffStats={tiffStats}
                now={now}
                activeTabKey={activeTabKey}
                loading={loading}
                setParameter={setParameter}
                setParameter2={setParameter2}
                setSubmitted={setSubmitted}
                parameter={parameter}
                parameter2={parameter2}
                increment={increment}
                setIncrement={setIncrement}
                incrementUnit={incrementUnit}
                setIncrementUnit={setIncrementUnit}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
}

export { MyTiff };
