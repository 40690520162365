import React from "react";
import Link from "@mui/material/Link";
import "./SocialMedia.css";

function SocialMedia() {
  return (
    <div className="social-media-container">
      {/* <Link
        href="https://twitter.com/ptweather"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="twitter" src="./assets/twitter.svg" alt="Twitter" />
      </Link> */}
      <Link
        href="https://www.facebook.com/profile.php?id=61555363594063"
        target="_blank"
        rel="noopener noreferrer"
      >
      <img className="facebook" src="./assets/facebook.png" alt="Facebook" />
      </Link>
      <Link
        href="https://www.youtube.com/@PTWeather"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="youtube" src="./assets/youtube.svg" alt="YouTube" />
        <span className="social-text">Tutorials and Videos</span>
      </Link>
    </div>
  );
}

export default SocialMedia;
