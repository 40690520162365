// RangeTab.js
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { addHours, differenceInHours } from "date-fns";

import ModelDropDown from "./ModelDropDown";
import ParameterDropDown from "./ParameterDropDown";
import ModelStatistics from "./ModelStats";
import DatePickerComponent from "./DatePickerComponent";
import DatePickerControlsComponent from "./DatePickerControlsComponent";
import DataStats from "./DataStats";
import ColorRamp from "./ColorRamp";
import OpacitySlider from "./OpacitySlider/OpacitySlider";

import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerStyles.css";
import styles from "../SideForm.module.css";

const RangeTab = ({
  register,
  control,
  handleSubmit,
  onSubmit,
  errors,
  setValue,
  selectedModel,
  setSelectedModel,
  modelRun,
  setModelRun,
  allModels,
  forecastHour,
  tiffStats,
  now,
  activeTabKey,
  loading,
  setParameter,
  setSubmitted,
  parameter,
  setRampType,
  setRampScale,
  rampType,
  rampScale,
  setColorBlind,
  opacity,
  setOpacity,
  increment,
  setIncrement,
  incrementUnit,
  setIncrementUnit,
}) => {
  const [selectedStartDate, setSelectedStartDate] = useState(now);
  const [selectedEndDate, setSelectedEndDate] = useState(addHours(now, 6));
  const [isLocalTime, setIsLocalTime] = useState(true); // true for UTC, false for local

  const handleNumberChange = (event) => {
    setIncrement(parseInt(event.target.value));
  };

  const handleOnSubmit = () => {
    setSubmitted(true);
  };

  const handleNextIncrement = () => {
    const newStartDate = new Date(selectedStartDate);
    const newEndDate = new Date(selectedEndDate);
    let actualIncrement = increment;
  
    if (incrementUnit === "months") {
      newStartDate.setMonth(newStartDate.getMonth() + actualIncrement);
      newEndDate.setMonth(newEndDate.getMonth() + actualIncrement);
    } else if (incrementUnit === "years") {
      newStartDate.setFullYear(newStartDate.getFullYear() + actualIncrement);
      newEndDate.setFullYear(newEndDate.getFullYear() + actualIncrement);
    } else if (incrementUnit === "hours") {
      let futureForecastHour = Number(forecastHour) + actualIncrement;
      let divisible = futureForecastHour % 3;
  
      if (futureForecastHour > 120 && divisible !== 0) {
        const roundedUpToNearestThree = Math.ceil(futureForecastHour / 3) * 3;
        actualIncrement = roundedUpToNearestThree - Number(forecastHour);
        setIncrement(actualIncrement);
      }
  
      newStartDate.setUTCHours(newStartDate.getUTCHours() + actualIncrement);
      newEndDate.setUTCHours(newEndDate.getUTCHours() + actualIncrement);
    } else {
      newStartDate.setDate(newStartDate.getDate() + actualIncrement);
      newEndDate.setDate(newEndDate.getDate() + actualIncrement);
    }
  
    setSelectedStartDate(newStartDate);
    setSelectedEndDate(newEndDate);
    setValue("range_start_date", newStartDate);
    setValue("range_end_date", newEndDate);
  };
  
  const handlePreviousIncrement = () => {
    const newStartDate = new Date(selectedStartDate);
    const newEndDate = new Date(selectedEndDate);
    let actualIncrement = increment;
    let futureForecastHour = Number(forecastHour) - actualIncrement;
    let divisible = futureForecastHour % 3;
  
    if (incrementUnit === "months") {
      newStartDate.setMonth(newStartDate.getMonth() - actualIncrement);
      newEndDate.setMonth(newEndDate.getMonth() - actualIncrement);
    } else if (incrementUnit === "years") {
      newStartDate.setFullYear(newStartDate.getFullYear() - actualIncrement);
      newEndDate.setFullYear(newEndDate.getFullYear() - actualIncrement);
    } else if (incrementUnit === "hours") {
      if (futureForecastHour > 120 && divisible !== 0) {
        const roundedDownToNearestThree = Math.floor(futureForecastHour / 3) * 3;
        actualIncrement = Math.abs(roundedDownToNearestThree - Number(forecastHour));
        setIncrement(actualIncrement);
      }
  
      newStartDate.setUTCHours(newStartDate.getUTCHours() - actualIncrement);
      newEndDate.setUTCHours(newEndDate.getUTCHours() - actualIncrement);
    } else {
      newStartDate.setDate(newStartDate.getDate() - actualIncrement);
      newEndDate.setDate(newEndDate.getDate() - actualIncrement);
    }
  
    setSelectedStartDate(newStartDate);
    setSelectedEndDate(newEndDate);
    setValue("range_start_date", newStartDate);
    setValue("range_end_date", newEndDate);
  };
  
  // No operation variable to pass handleBlur later
  const noop = () => {};
  // Add this function to extract the date from the model run
  const extractDateFromModelRun = (modelRun) => {
    const dateMatch = modelRun.match(/(\d{4}-\d{2}-\d{2})/);
    const timeMatch = modelRun.match(/\((\d{2})Z\)/);

    if (dateMatch && timeMatch) {
      const [year, month, day] = dateMatch[1].split("-").map(Number);
      const hour = Number(timeMatch[1]);
      return new Date(Date.UTC(year, month - 1, day, hour));
    }
    return null;
  };
  const modelRunDate = extractDateFromModelRun(modelRun);

  useEffect(() => {
    setValue("range_start_date", selectedStartDate);
    setValue("range_end_date", selectedEndDate);
  }, [setValue, selectedStartDate, selectedEndDate]);

  return (
    <>
      <div style={{ marginTop: "0px" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group
            className="mb-3"
            controlId="model"
            {...register("range_model")}
          >
            <Form.Label style={{ fontSize: "11px", marginBottom: "0px" }}>
              Model:{" "}
            </Form.Label>
            <ModelDropDown
              prefix="range_"
              setSelectedModel={setSelectedModel}
              selectedModel={selectedModel}
              register={register}
              activeTabKey={activeTabKey}
            />
            {errors.model && <p className="errorMsg">{errors.model.message}</p>}
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="parameter"
            {...register("range_parameter")}
          >
            <Form.Label style={{ fontSize: "11px", marginBottom: "0px" }}>
              Parameter:{" "}
            </Form.Label>
            <ParameterDropDown
              prefix="range_"
              selectedModel={selectedModel}
              register={register}
              setValue={setValue}
              activeTabKey={activeTabKey}
              parameter={parameter}
              setParameter={setParameter}
            />

            {errors.parameter && (
              <p className="errorMsg">{errors.parameter.message}</p>
            )}
          </Form.Group>
          <DatePickerComponent
            control={control}
            selectedDate={selectedStartDate}
            setSelectedDate={setSelectedStartDate}
            setValue={setValue}
            modelRunDate={modelRunDate}
            name={"start_date"}
            modelRun={modelRun}
            selectedModel={selectedModel}
            isLocalTime={isLocalTime}
            setIsLocalTime={setIsLocalTime}
            minDate={modelRunDate}
            maxDate={addHours(new Date(), allModels[modelRun])}
            errors={errors}
          />
          <DatePickerComponent
            control={control}
            selectedDate={selectedEndDate}
            setSelectedDate={setSelectedEndDate}
            setValue={setValue}
            modelRunDate={modelRunDate}
            name={"end_date"}
            modelRun={modelRun}
            selectedModel={selectedModel}
            isLocalTime={isLocalTime}
            setIsLocalTime={setIsLocalTime}
            minDate={selectedStartDate}
            selected={
              isLocalTime
                ? selectedEndDate
                : new Date(
                    selectedEndDate.getTime() +
                      selectedEndDate.getTimezoneOffset() * 60000
                  )
            }
            showTimeSelect={
              selectedModel !== "era5_monthly" &&
              selectedModel !== "era5_daily" &&
              selectedModel !== "gfs_daily" &&
              selectedModel !== "reanalysis_season" &&
              selectedModel !== "reanalysis_24"
            }
            maxDate={addHours(new Date(), allModels[modelRun])}
            errors={errors}
          />
          <DatePickerControlsComponent
            increment={increment}
            setIncrement={setIncrement}
            incrementUnit={incrementUnit}
            setIncrementUnit={setIncrementUnit}
            selectedModel={selectedModel}
            loading={loading}
            handleOnSubmit={handleOnSubmit}
            handleNextIncrement={handleNextIncrement}
            handlePreviousIncrement={handlePreviousIncrement}
            handleNumberChange={handleNumberChange}
            handleBlur={noop}
            name={"Increment:"}
          />
        </form>
      </div>
      <div className={styles.ramps_stats_container}>
        <DataStats stats={tiffStats} />
        <ModelStatistics
          modelRun={modelRun}
          setModelRun={setModelRun}
          allModels={allModels}
          forecastHour={forecastHour}
        />
        <div className={styles.sideform_color_ramp}>
          <ColorRamp
            setRampType={setRampType}
            setRampScale={setRampScale}
            rampType={rampType}
            rampScale={rampScale}
            setColorBlind={setColorBlind}
            parameter={parameter}
            name={"ramp_range"}
          />
          <OpacitySlider opacity={opacity} setOpacity={setOpacity} />
        </div>
      </div>
    </>
  );
};

export default RangeTab;
