import React, { useState } from "react";
import styles from "../SideForm.module.css";

export default function CorrelationTypeDropDown({ register }) {
  const [selectedCorrelation, setSelectedCorrelation] = useState("pearson");

  const handleChange = (e) => {
    setSelectedCorrelation(e.target.value);
  };

  return (
    <select
      {...register("correlation_type")}
      className={styles.dropdown_model}
      onChange={handleChange}
      value={selectedCorrelation}
    >
      <optgroup label="Correlation Type">
        <option value="pearson">Pearson (linear)</option>
        <option value="spearman">Spearman (monotonic)</option>
        <option value="distance">Distance (non-linear)</option>
      </optgroup>
    </select>
  );
}
