import React, { useState, useRef, useEffect, useMemo } from "react";
import { FeatureGroup, Polygon, Marker } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { useCoordinates } from "./CoordinatesContext";
import ChangeView from "./ChangeView";
import LocationButton from "./LocationButton";
import { useMap } from "react-leaflet";
import L from "leaflet";

const LeafletDrawShapes = ({
  setWeatherFile,
  isActive,
  isMeasuringArea,
  isMeasuringDistance,
  submitted,
  setSubmitted,
  activeTabKey,
  correlate,
  firstPolygonCoords,
  setFirstPolygonCoords,
  secondPolygonCoords,
  setSecondPolygonCoords,
}) => {
  const [coordinates, setCoordinates] = useCoordinates();
  const [newPolygonDrawn, setNewPolygonDrawn] = useState(false);
  const [hasDrawnPolygon, setHasDrawnPolygon] = useState(false);
  const rectangleCountRef = useRef(0);
  const lastPolygonIdRef = useRef(null);
  const correlateRef = useRef(correlate);
  const prevCorrelateRef = useRef(correlate);
  const polygonLayersRef = useRef([]);

  const map = useMap();

  const defaultPolygonCoords = useMemo(
    () => [
      { lng: -151.56, lat: 9.86 },
      { lng: -151.56, lat: 58.69 },
      { lng: -44.35, lat: 58.69 },
      { lng: -44.35, lat: 9.86 },
      { lng: -151.56, lat: 9.86 },
    ],
    []
  );

  const greenDotIcon = L.divIcon({
    className: "green-dot-icon",
    html: '<div style="background-color: green; width: 20px; height: 20px; border-radius: 50%; position: relative; left: -10px; top: -10px;"></div>',
    iconSize: [20, 20],
    iconAnchor: [10, 10],
  });

  const blueDotIcon = L.divIcon({
    className: "blue-dot-icon",
    html: '<div style="background-color: blue; width: 20px; height: 20px; border-radius: 50%; position: relative; left: -10px; top: -10px;"></div>',
    iconSize: [20, 20],
    iconAnchor: [10, 10],
  });

  const redDotIcon = L.divIcon({
    className: "red-dot-icon",
    html: '<div style="background-color: red; width: 20px; height: 20px; border-radius: 50%; position: relative; left: -10px; top: -10px;"></div>',
    iconSize: [20, 20],
    iconAnchor: [10, 10],
  });

  function differentiateCoordinates(jsonString) {
    try {
      const data = JSON.parse(jsonString);
      if (Array.isArray(data)) {
        return data.every(
          (item) => item.hasOwnProperty("lat") && item.hasOwnProperty("lng")
        )
          ? "polygon"
          : "error";
      } else if (
        typeof data === "object" &&
        data.hasOwnProperty("lat") &&
        data.hasOwnProperty("lng")
      ) {
        return "point";
      }
      return "error";
    } catch {
      return "error";
    }
  }

  useEffect(() => {
    setCoordinates(JSON.stringify(defaultPolygonCoords));
  }, []);

  useEffect(() => {
    // Update correlateRef to always have the latest value
    correlateRef.current = correlate;

    // Detect changes in correlate
    if (prevCorrelateRef.current !== correlate) {
      if (!prevCorrelateRef.current && correlate) {
        // Correlate changed from false to true
        // Handle the transition from single polygon mode to dual shape mode

        // Remove all existing layers
        polygonLayersRef.current.forEach((layer) => {
          map.removeLayer(layer);
        });

        // Clear the layers from the ref
        polygonLayersRef.current = [];

        // Reset states related to single polygon mode
        lastPolygonIdRef.current = null;
        setCoordinates(null);
        setNewPolygonDrawn(false);

        // Prepare states for dual shape mode
        rectangleCountRef.current = 0;
        setFirstPolygonCoords(null);
        setSecondPolygonCoords(null);
      } else if (prevCorrelateRef.current && !correlate) {
        // Correlate changed from true to false
        // Handle the transition from dual shape mode to single polygon mode

        // Remove all existing layers
        polygonLayersRef.current.forEach((layer) => {
          map.removeLayer(layer);
        });

        // Clear the layers from the ref
        polygonLayersRef.current = [];

        // Reset states related to dual shape mode
        rectangleCountRef.current = 0;
        setFirstPolygonCoords(null);
        setSecondPolygonCoords(null);

        // Prepare states for single polygon mode
        lastPolygonIdRef.current = null;
        setCoordinates(null);
        setNewPolygonDrawn(false);
      }

      // Update the previous value
      prevCorrelateRef.current = correlate;
    }
  }, [correlate, map]);

  const onCreated = (e) => {
    const { layer, layerType } = e;
    const { _leaflet_id } = layer;

    // Store the layer reference
    polygonLayersRef.current.push(layer);
    setHasDrawnPolygon(true);

    if (layerType === "rectangle" || layerType === "marker") {
      const layerCoordinates =
        layerType === "rectangle"
          ? JSON.stringify(layer._latlngs[0])
          : JSON.stringify(layer.getLatLng());

      if (correlateRef.current) {
        // Handle correlate true case
        if (rectangleCountRef.current >= 2) {
          // Remove the layer and show an error
          map.removeLayer(layer);
          alert(
            "Maximum of two shapes reached. Cannot add more. Clear current shapes to redraw"
          );
        } else {
          rectangleCountRef.current += 1;
          if (rectangleCountRef.current === 1) {
            setFirstPolygonCoords(layerCoordinates);
          } else if (rectangleCountRef.current === 2) {
            setSecondPolygonCoords(layerCoordinates);
          }
          setNewPolygonDrawn(true);
        }
      } else {
        // Handle correlate false case
        // Remove previous layer if it exists
        if (lastPolygonIdRef.current && map._layers[lastPolygonIdRef.current]) {
          map.removeLayer(map._layers[lastPolygonIdRef.current]);
        }
        lastPolygonIdRef.current = _leaflet_id;
        setCoordinates(layerCoordinates);
        setFirstPolygonCoords(null);
        setSecondPolygonCoords(null);
        setNewPolygonDrawn(true);
      }
    } else if (layerType === "polygon") {
      // Remove previous layer if it exists
      if (lastPolygonIdRef.current && map._layers[lastPolygonIdRef.current]) {
        map.removeLayer(map._layers[lastPolygonIdRef.current]);
      }
      lastPolygonIdRef.current = _leaflet_id;
      setCoordinates(JSON.stringify(layer._latlngs[0]));
      setNewPolygonDrawn(true);
    }
  };

  // Update the ref whenever `correlate` changes
  useEffect(() => {
    correlateRef.current = correlate;
  }, [correlate]);
  const onDeleted = (e) => {
    if (correlateRef.current) {
      rectangleCountRef.current -= e.layers.getLayers().length; // Adjust the count
      if (rectangleCountRef.current < 0) rectangleCountRef.current = 0;
      if (rectangleCountRef.current === 0) {
        setFirstPolygonCoords(null);
        setSecondPolygonCoords(null);
      } else if (rectangleCountRef.current === 1) {
        setSecondPolygonCoords(null);
      }
    } else {
      // Reset lastPolygonIdRef for all cases
      lastPolygonIdRef.current = null;
      setWeatherFile(null);
    }
  };

  useEffect(() => {
    if (coordinates && submitted) {
      const type = differentiateCoordinates(coordinates);
      let parsedCoordinates;

      setSubmitted(false);

      if (type === "polygon") {
        parsedCoordinates = JSON.parse(coordinates).map((coord) => [
          coord.lat,
          coord.lng,
        ]);
        const bounds = L.latLngBounds(parsedCoordinates);
        map.fitBounds(bounds);
      } else if (type === "point") {
        const { lat, lng } = JSON.parse(coordinates);
        map.setView([lat, lng], 12);
      }
    }
  }, [submitted, coordinates, map, setSubmitted]);

  const [coords, setCoords] = useState(null);

  return (
    <FeatureGroup>
      <LocationButton onLocationFound={(e) => setCoords(e.latlng)} />
      {coords && <ChangeView coords={coords} />}
      <EditControl
        position="topleft"
        onCreated={onCreated}
        onDeleted={onDeleted}
        draw={{
          rectangle:
            isActive && !isMeasuringArea && !isMeasuringDistance
              ? {
                  allowIntersection: false,
                  showArea: false,
                  shapeOptions: { color: "blue", opacity: 0.6, fillOpacity: 0 },
                }
              : false,
          polygon:
            isActive &&
            !isMeasuringArea &&
            !isMeasuringDistance &&
            activeTabKey !== "graph" &&
            activeTabKey !== "correlation"
              ? {
                  allowIntersection: false,
                  showArea: false,
                  shapeOptions: { color: "blue", opacity: 0.6, fillOpacity: 0 },
                }
              : false,
          marker:
            activeTabKey === "graph" || activeTabKey === "correlation"
              ? { icon: greenDotIcon }
              : false,
          circle: false,
          polyline: false,
          circlemarker: false,
        }}
        edit={{
          edit: false, // Disable editing functionality and remove button
        }}
      />

      {!hasDrawnPolygon && !correlate && (
        <Polygon
          positions={defaultPolygonCoords.map((coord) => [
            coord.lat,
            coord.lng,
          ])}
          color="blue"
          fillOpacity={0}
        />
      )}

      {/* Display the first and second shapes if correlate is true */}
      {correlate && firstPolygonCoords && (
        <>
          {differentiateCoordinates(firstPolygonCoords) === "polygon" ? (
            <Polygon
              positions={JSON.parse(firstPolygonCoords).map((coord) => [
                coord.lat,
                coord.lng,
              ])}
              color="blue"
              fillOpacity={0.3}
            />
          ) : (
            <Marker
              position={JSON.parse(firstPolygonCoords)}
              icon={blueDotIcon}
            />
          )}
        </>
      )}
      {correlate && secondPolygonCoords && (
        <>
          {differentiateCoordinates(secondPolygonCoords) === "polygon" ? (
            <Polygon
              positions={JSON.parse(secondPolygonCoords).map((coord) => [
                coord.lat,
                coord.lng,
              ])}
              color="red"
              fillOpacity={0.3}
            />
          ) : (
            <Marker
              position={JSON.parse(secondPolygonCoords)}
              icon={redDotIcon}
            />
          )}
        </>
      )}
    </FeatureGroup>
  );
};

export default LeafletDrawShapes;
