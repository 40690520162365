import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { ForecastCoords } from "../LoadRaster";

function GenerateForecastData(selectedDate, setSelectedDate, selectedForecastModel) {
  const [currentDate, setCurrentDate] = useState(selectedDate);
  const [isLastDay, setIsLastDay] = useState(false); // Track if it's the last day
  const [alldata, setAlldata] = useState([]); // Store forecast data for the current day

  const lon = Number(ForecastCoords.Provider[0]);
  const lat = Number(ForecastCoords.Provider[1]);

  const minDate = new Date(); // Today
  minDate.setHours(0, 0, 0, 0);

  const maxDate = new Date(new Date().setHours(new Date().getHours() + 384)); // Max forecast range
  maxDate.setHours(0, 0, 0, 0);

  // Function to request forecast for a specific day
  const getForecastForDate = useCallback(
    (date, setNextDayCheck = false) => {
      let API_KEY = process.env.REACT_APP_API_KEY;
      const timezoneOffsetHours = new Date().getTimezoneOffset() / 60;
      const formattedDateDay = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
  
      return axios
        .post(`${backendUrl}/api/forecast`, {
          location: [lon, lat],
          model: selectedForecastModel,
          day: formattedDateDay,
          model_res: API_KEY,
          offset: timezoneOffsetHours,
        })
        .then((response) => {
          if (
            response.data.weather_data &&
            response.data.weather_data.length > 0
          ) {
            if (setNextDayCheck) {
              return true; // Data exists
            } else {
              setAlldata(response.data.weather_data); // Update weather data
              setIsLastDay(false); // Data exists, not the last day
              return true;
            }
          } else {
            if (setNextDayCheck) {
              return false; // No data
            } else {
              setAlldata([]); // Clear alldata when no data is returned
              setIsLastDay(true); // No data for the current day
              return false;
            }
          }
        })
        .catch((error) => {
          console.error(error);
          if (setNextDayCheck) {
            return false; // Return false in case of error
          } else {
            setAlldata([]); // Clear alldata in case of error
            setIsLastDay(true);
            return false;
          }
        });
    },
    [lon, lat, selectedForecastModel]
  );

  // Function to handle the "Next Day" button click
  const handleNextDay = () => {
    // Create a new date object based on the current date
    const nextDay = new Date(currentDate);
    
    // Add one day using getDate() to properly account for DST changes
    nextDay.setDate(nextDay.getDate() + 1);
  
    // Normalize the time to the start of the day to avoid time discrepancies
    nextDay.setHours(0, 0, 0, 0);
  
    if (nextDay <= maxDate) {
      // Check if there's data for the next day without updating the current date
      getForecastForDate(nextDay, true).then((dataExists) => {
        if (dataExists) {
          setCurrentDate(nextDay);
          setSelectedDate(nextDay); // Update if data exists
          setIsLastDay(false); // Reset last day status
        } else {
          setIsLastDay(true); // No data exists for the next day
        }
      }).catch((error) => {
        console.error("Error fetching forecast for next day:", error);
      });
    }
  };

  // Function to handle the "Previous Day" button click
  const handlePreviousDay = () => {
    const previousDay = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000); // Subtract one day
    previousDay.setHours(0, 0, 0, 0);

    if (previousDay >= minDate) {
      setCurrentDate(previousDay);
      setSelectedDate(previousDay);
      setIsLastDay(false); // Reset last day status when going back
    }
  };

  // Safeguard against empty or undefined `alldata` array
  const weatherdata = alldata ? alldata.map((data) => {
    const now = new Date();
    const timezoneOffsetMinutes = now.getTimezoneOffset();
    const dateObj = new Date(data.forecast_time);
    const localDateObj = new Date(dateObj.getTime() - timezoneOffsetMinutes * 60 * 1000);
    const localTime = localDateObj.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    
    const formattedTime = localTime.replace(/:00 /, "").toLowerCase();
    const simplifiedTime = formattedTime.replace(/^0/, "");
    
    return {
      time: simplifiedTime,
      condition: data.condition,
      temp: data.temperature,
      precip: data.precipitation,
      wind: data.wind,
      gusts: data.windgusts,
      clouds: data.cloudcover,
      model_run: data.model_run_date
    };
  }) : [];

  useEffect(() => {
    getForecastForDate(currentDate); // Fetch forecast for current date on load
  }, [currentDate, getForecastForDate]);

  // Update currentDate when selectedDate changes
  useEffect(() => {
    setCurrentDate(selectedDate);
  }, [selectedDate]);

  return { weatherdata, handleNextDay, handlePreviousDay, isLastDay };
}

export default GenerateForecastData;
