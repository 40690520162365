import React from "react";
import { Form, Dropdown } from "react-bootstrap";

import styles from "../SideForm.module.css";

export default function ModelStatistics({
  modelRun,
  setModelRun,
  allModels,
  forecastHour,
}) {
  // Update the selected model run when a new option is chosen
  const handleSelect = (selectedKey) => {
    setModelRun(selectedKey);
  };

  return (
    <div className={styles.model_stats_container}>
      <div className={styles.model_run_box_div}>
        <Form.Label style={{ marginRight: "7px" }}>Model run:</Form.Label>
        <Dropdown onSelect={handleSelect} className={styles.model_run_box}>
          <Dropdown.Toggle
            variant="success"
            id="dropdown-instant"
            className={styles.model_run_default}
          >
            {modelRun} {/* Display the currently selected model run */}
          </Dropdown.Toggle>

          <Dropdown.Menu className={styles.model_run_dropdown}>
            {Object.keys(allModels).map((run, index) => (
              <Dropdown.Item
                key={index}
                eventKey={run}
                className={styles.model_run_box_font}
              >
                {run}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className={styles.model_hour_box_div}>
        <Form.Label className={styles.label_margin_right}>
          Forecast hour:{" "}
          <input
            type="text"
            value={forecastHour}
            readOnly={true}
            className={styles.model_current_hour_box}
          />
        </Form.Label>
        <Form.Label>
          Available hours:{" "}
          <input
            type="text"
            value={allModels[modelRun] || "N/A"} // Display the maxHour based on the selected model run
            readOnly={true}
            className={styles.model_current_hour_box}
          />
        </Form.Label>
      </div>
    </div>
  );
}
